<template>
  <div>
    <b-row>
      <b-col>
         <h5 class="mt-2 text-left">
          {{ $t('Personal Details') }}
        </h5>
      </b-col>
    </b-row>
    <b-form class="text-left" @submit.prevent="handleSubmit">
      <b-row>
        <b-col cols="6">
          <b-form-group id="groupFirstname" :label="$t('address.FirstName')" label-for="firstname">
            <base-input
              name="firstname"
              v-validate="'required|alpha'"
              v-model="form.firstname"
              type="text"
              :readonly= readOnly
              :validations="[
                {
                  condition: errors.has('firstname'),
                  text: errors.first('firstname')
                }
              ]"
            >
            </base-input>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group id="groupLastname" :label="$t('address.LastName')" label-for="lastname">
            <base-input
              name="lastname"
              v-validate="'required|alpha'"
              v-model="form.lastname"
              type="text"
              :readonly= readOnly
              :validations="[
                {
                  condition: errors.has('lastname'),
                  text: errors.first('lastname')
                }
              ]"
            >
            </base-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group id="groupEmail" :label="$t('address.Email')" label-for="email">
            <base-input
              name="email"
              v-validate="'required|email'"
              v-model="form.email"
              type="text"
              :readonly= readOnly
              :validations="[
                {
                  condition: errors.has('email'),
                  text: errors.first('email')
                }
              ]"
            >
            </base-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="text-center">
      <b-col cols="12">
        <b-button variant="primary" v-if="isModify === false" @click="editPersonalInfo">{{ $t('Edit') }}</b-button>
        <b-button type="submit" variant="primary" v-else>{{ $t('Save') }}</b-button>
      </b-col>
    </b-row>
    </b-form>
  </div>
</template>

<script>
import BaseInput from '@/components/Form/BaseInput'
import _ from 'lodash'
import { toast } from '@/modules/notifications'

export default {
  data () {
    return {
      form: {
        firstname: '',
        lastname: '',
        email: ''
      },
      isModify: false,
      readOnly: true
    }
  },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  mounted () {
    _.assign(this.form, this.customer)
  },
  methods: {
    editPersonalInfo () {
      this.isModify = true
      this.readOnly = false
    },
    handleSubmit () {
      const self = this
      self.$validator.validateAll().then((isValid) => {
        if (isValid) {
          self.$store.commit('ui/showLoader')
          self.$store.dispatch('user/update', self.form)
            .then(user => {
              this.readOnly = true
              this.isModify = false
              this.$store.commit('ui/hideLoader', false)
              toast.success(self.$t('Change successful!'))
            })
            .catch(res => {
              this.$store.commit('ui/hideLoader', false)
              res && res.body && toast.error(self.$t(res.body.result))
            })
        } else {
          toast.error(self.$t('Remember to fill in all the fields!'))
        }
      })
    }
  },
  components: {
    BaseInput
  }
}
</script>

<style lang="scss" scoped>
dl.dl-horizontal {
  display: block;
  dt {
    float: left;
    text-align: left;
    width: 86px;
  }
  dd {
    text-align: left;
  }
}
</style>
