<template>
  <div>
    <b-row>
      <b-col>
         <h5 class="mt-2 text-left">
          {{ $t('ShippingAddress') }}
        </h5>
      </b-col>
    </b-row>
    <div v-if="hasAddresses">
      <div v-for="(address, index) in addresses" :key="address.id">
        <address-detail :address="address" @edit="editModal(address, index)" @remove="showModalConfirm(index)"/>
      </div>
    </div>
    <div v-else class="text-left">{{ $t('No address associated')}}</div>
    <b-button variant="primary" @click="addAddressModal">{{ $t('Add Address') }}</b-button>
    <edit-address-modal
      :address="editObj.address"
      :index="editObj.index"
      :modal-show="modalShow"
      @updateModal="updateModal"
      @cancel="hideModal"
    />
    <modal-confirm
      :modal-show="modalShowConfirm"
      :message="$t('messageRemoveAddr')"
      :options="editObj"
      @updateModalConfirm="updateModalConfirm"
      @cancel="updateModalConfirm"
      @confirm="removeAddress"
    />
  </div>
</template>

<script>
import AddressDetail from './AddressDetail.vue'
import EditAddressModal from './EditAddressModal.vue'
import _ from 'lodash'
import { DefaultAddress } from '@/store/modules/checkout/defaultValues'
import ModalConfirm from '../Modal/ModalConfirm.vue'
import { toast } from '@/modules/notifications'

export default {
  props: {
    addresses: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      editObj: {
        index: null,
        address: { ...DefaultAddress }
      },
      modalShow: false,
      modalShowConfirm: false
    }
  },
  computed: {
    hasAddresses () {
      return this.addresses.length > 0
    }
  },
  methods: {
    hideModal () {
      this.updateModal(false)
    },
    updateModal (value) {
      this.modalShow = value
    },
    editModal (address, index) {
      this.editObj.address = _.assign({}, address)
      this.editObj.address.region = _.assign({}, address.region)
      this.editObj.index = index
      this.modalShow = true
    },
    addAddressModal () {
      this.editObj.index = null
      this.editObj.address = { ...DefaultAddress }
      delete this.editObj.address.useotheraddress
      this.modalShow = true
    },
    showModalConfirm (index) {
      this.editObj.index = index
      this.modalShowConfirm = true
    },
    removeAddress (obj) {
      this.modalShowConfirm = false
      this.$store.commit('ui/showLoader')
      this.$store.dispatch('user/deleteAddress', obj.index)
        .then(user => {
          this.$store.commit('ui/hideLoader', false)
          toast.success(this.$t('Change successful!'))
        })
        .catch(res => {
          this.$store.commit('ui/hideLoader', false)
          toast.error(this.$t(res.body.result))
        })
    },
    updateModalConfirm (value) {
      this.modalShowConfirm = value
    }
  },
  components: {
    AddressDetail,
    EditAddressModal,
    ModalConfirm
  }
}
</script>
