<template>
  <!-- Modal -->
  <b-form class="text-left" @submit.prevent="updateAddress" v-if="ModalShowComputed">
    <b-modal :title="titleModal" v-model="ModalShowComputed" @shown="initValidation">
      <b-row>
        <b-col cols="6">
          <b-form-group id="groupFirstname" :label="$t('address.FirstName') + '*'" label-for="firstname">
            <base-input
              name="firstname"
              v-validate="'required'"
              v-model.trim="address.firstname"
              type="text"
              :focus="true"
              :validations="[
                {
                  condition: errors.has('firstname'),
                  text: errors.first('firstname')
                }
              ]"
            >
            </base-input>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group id="groupLastname" :label="$t('address.LastName') + '*'" label-for="lastname">
            <base-input
              name="lastname"
              v-validate="'required'"
              v-model.trim="address.lastname"
              type="text"
              :validations="[
                {
                  condition: errors.has('lastname'),
                  text: errors.first('lastname')
                }
              ]"
            >
            </base-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group id="groupStreet" :label="$t('address.StreetAddress') + '*'" label-for="street">
            <base-input
              name="street"
              v-validate="'required'"
              v-model.trim="street"
              type="text"
              :validations="[
                {
                  condition: errors.has('street'),
                  text: errors.first('street')
                }
              ]"
            >
            </base-input>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group id="groupCity" :label="$t('address.City') + '*'" label-for="city">
            <base-input
              name="city"
              v-validate="'required'"
              v-model.trim="address.city"
              type="text"
              :validations="[
                {
                  condition: errors.has('city'),
                  text: errors.first('city')
                }
              ]"
            >
            </base-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group id="groupProvince" :label="$t('address.Province') + '*'" label-for="province">
            <select-province
              name="state"
              v-validate="'required'"
              v-model="address.region.region_code"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group id="groupZipCode" :label="$t('address.ZipCode') + '*'" label-for="zipCode">
            <base-input
              name="zipCode"
              v-validate="'required|numeric'"
              v-model.trim="address.postcode"
              type="text"
              :validations="[
                {
                  condition: errors.has('zipCode'),
                  text: errors.first('zipCode')
                }
              ]"
            >
            </base-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group id="groupPhone" :label="$t('address.PhoneNumber') + '*'" label-for="phone">
            <base-input
              name="phone"
              v-validate="'required|numeric'"
              v-model.trim="address.telephone"
              type="text"
              :validations="[
                {
                  condition: errors.has('phone'),
                  text: errors.first('phone')
                }
              ]"
            >
            </base-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group label-for="shipping_default">
            <base-check-box
              name="shipping_default"
              v-model="address.default_shipping"
              :label="$t('Shipping Default')"
            >
            </base-check-box>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row slot="modal-footer" class="w-100 text-right">
        <b-col cols="12">
          <b-button variant="primary" @click="closeModal">{{ $t('Cancel') }}</b-button>
          <b-button variant="primary" class="ml-3" type='submit'>{{ $t('Save Address') }}</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </b-form>
</template>

<script>
import BaseInput from '@/components/Form/BaseInput'
import BaseCheckBox from '@/components/Form/BaseCheckBox'
import SelectProvince from '../SelectProvince.vue'
import { toast } from '@/modules/notifications'
import _ from 'lodash'

export default {
  props: {
    address: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: null
    },
    ModalShow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    street: {
      get () {
        return _.join(this.address.street, ', ')
      },
      set (newValue) {
        this.address.street = [newValue]
      }
    },
    ModalShowComputed: {
      get () {
        return this.ModalShow
      },
      set (newValue) {
        this.$emit('updateModal', newValue)
      }
    },
    titleModal () {
      if (this.address.id == null) {
        return this.$t('Add new address')
      }
      return this.$t('Edit Address')
    }
  },
  methods: {
    initValidation () {
      this.$validator.reset()
    },
    closeModal () {
      this.$emit('cancel')
    },
    updateAddress () {
      const self = this
      self.$validator.validateAll().then((isValid) => {
        if (isValid) {
          this.$store.commit('ui/showLoader')
          self.$store.dispatch('user/updateAddressList', { address: this.address, index: this.index })
            .then(user => {
              this.$store.commit('ui/hideLoader', false)
              this.$validator.reset()
              toast.success(self.$t('Change successful!'))
            })
            .catch(({ response }) => {
              this.$store.commit('ui/hideLoader', false)
              toast.error(self.$t(response.data.result))
            })
          self.$emit('updateModal', false)
        } else {
          toast.error(self.$t('Remember to fill in all the fields!'))
        }
      })
    }
  },
  components: {
    BaseInput,
    BaseCheckBox,
    SelectProvince
  }
}
</script>
